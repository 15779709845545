.mat-button-base:hover {
  background-color: #e7e7e7;
}

.loading .mdc-linear-progress__bar-inner {
  background-color: #eaeaea !important;
  border-color: #eaeaea !important;
  height: 50px !important;
}

.loading .mdc-linear-progress__buffer-bar {
  background-color: #e2e2e2 !important;
  border-color: #e2e2e2 !important;
  height: 50px !important;
}

.mat-mdc-progress-bar.loading {
  height: 50px !important;
  margin: 10px 0;
}

.drag-box {
  font-size: 16px !important;
  min-height: 70px;
  padding: 20px 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  background: white;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-box:last-child {
  border: none;
}

.charity-list.cdk-drop-list-dragging .drag-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.success-snackbar {
  --mdc-snackbar-container-color: #E7FFE9 !important;
  --mat-mdc-snack-bar-button-color: #004207;

  .mat-mdc-snack-bar-label.mdc-snackbar__label {
    text-transform: uppercase;
    font-weight: bold;
    color: #004207 !important;
  }

  border: 2px solid #004207;
  border-radius: 4px;

  mat-icon {
    color: #004207 !important;
  }
}

.failure-snackbar {
  --mdc-snackbar-container-color: #FFECEC !important;
  --mat-mdc-snack-bar-button-color: #610000;
  border: 2px solid #610000 !important;
  border-radius: 4px;

  .mat-mdc-snack-bar-label.mdc-snackbar__label {
    text-transform: uppercase;
    font-weight: bold;
    color: #610000 !important;
  }

  mat-icon {
    color: #610000 !important;
    margin-right: 0 !important;
  }
}


.mat-mdc-snack-bar-container .mdc-snackbar__label {
  color: white !important;
}
