@use '@angular/material' as mat;

@include mat.core();

/* You can add global styles to this file, and also import other style files */
@import './app/material-overrides';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-quartz.css';
@import "@coreui/coreui/scss/coreui";

html, body {
  height: 100%;
}

// this is because of CORE UI.
button {
  line-height: 1 !important;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

pre {
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
}

.disabled {
  opacity: 0.38;
  cursor: default !important;
}

.dark-grey {
  color: #686868 !important;
}

.red-text {
  color: #c32f36 !important;
}

.custom-paginator {
  text-align: end;
  padding: 10px 0;

  span {
    font-weight: bold;
  }

  mat-icon {
    &:hover {
      cursor: pointer;
    }

    margin: 0 4px;
    position: relative;
    top: 8px;
  }
}

.table-row-hover {
  &:hover {
    cursor: pointer;
  }
}

.green-text {
  color: green !important;
}

.primary-background-color {
  background-color: #427fe0 !important;
}

.meta-data-number {
  color: darkorange;
}

.meta-data-boolean {
  color: blue;
}

.meta-data-null {
  color: magenta;
}

.meta-data-key {
  color: red;
}

.flex-row {
  display: flex;
  flex-flow: row nowrap;
}

.flex-col {
  display: flex;
  flex-flow: column nowrap;
}

.flex-row-wrap {
  display: flex;
  box-sizing: border-box;
  flex-flow: row wrap;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.flex-full-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex {
  display: flex;
}

.gap-small {
  gap: 8px;
}

.gap-normal {
  gap: 16px;
}

.flex-50 {
  flex: 1 1 calc(50% - 16px);
  box-sizing: border-box;
  max-width: calc(50% - 16px);
}

.flex-33 {
  flex: 1 1 calc(33% - 16px);
  box-sizing: border-box;
  max-width: calc(33% - 16px);
}

.flex-25 {
  flex: 1 1 calc(25% - 8px);
  box-sizing: border-box;
  max-width: calc(25% - 8px);
}

.flex-75 {
  flex: 1 1 calc(75% - 8px);
  box-sizing: border-box;
  max-width: calc(75% - 8px);
}

.flex-66 {
  flex: 1 1 calc(66% - 8px);
  box-sizing: border-box;
  max-width: calc(66% - 8px);
}

@media (max-width: 1024px) {
  .flex-50,
  .flex-33,
  .flex-25,
  .flex-75,
  .flex-66 {
    flex: 100%;
    box-sizing: border-box;
    max-width: 100%;
  }
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.text-underline {
  text-decoration: underline;
}

.margin-large {
  margin: 32px;
}

.margin-top-large {
  margin-top: 32px !important;
}

.margin-bottom-large {
  margin-bottom: 32px !important;
}

.margin-right-large {
  margin-right: 32px !important;
}

.margin-left-large {
  margin-left: 32px !important;
}

.margin-left-med {
  margin-left: 16px !important;
}

.margin-left-small {
  margin-left: 8px !important;
}


.margin-right-med {
  margin-right: 16px !important;
}

.margin-top-small {
  margin-top: 8px !important;
}

.margin-right-small {
  margin-right: 8px !important;
}

.margin-top-med {
  margin-top: 16px !important;
}

.margin-bottom-med {
  margin-bottom: 16px;
}

.margin-bottom-small {
  margin-bottom: 8px;
}

.padding-large {
  padding: 32px;
}

.padding-left-large {
  padding-left: 32px;
}

.padding-right-large {
  padding-right: 32px;
}

.padding-bottom-large {
  padding-bottom: 32px;
}

.padding-left-med {
  padding-left: 16px;
}

.padding-right-med {
  padding-right: 16px;
}

.padding-med {
  padding: 16px;
}

.padding-top-med {
  padding-top: 16px !important;
}

.padding-bottom-med {
  padding-bottom: 16px !important;
}

.padding-left-small {
  padding-left: 10px !important;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.float-right {
  float: right;
}

.primary-button {
  background: #427fe0 !important;

  &:hover, &:focus, &:active {
    background: #2e77ed !important;
  }

  border-radius: 0 !important;
  color: white !important;
}

.center-margin {
  margin: 0 auto;
}

.detail-section {
  flex: 1 1 calc(33% - 16px);
  box-sizing: border-box;
  max-width: calc(33% - 16px);
  margin-bottom: 16px;
}

.banner-label {
  opacity: 0.5;
  text-transform: uppercase;
  color: white;
  font-size: 16px;;
}

.banner-value {
  color: white;
  font-size: 26px;
}

.detail-label {
  font-weight: 600;
  font-size: 1rem;
  color: #686868;
}

.detail-value {
  overflow-wrap: break-word;
  font-size: 1rem;
  color: #191919;
  margin-bottom: 8px;
}

.bold-text {
  font-weight: 500;
}

.display-inline-block {
  display: inline-block;
}

.display-block {
  display: block !important;
}

.display-none {
  display: none !important;
}

.info-icon {
  font-size: 16px;
  position: relative;
}

.drag-cursor {
  cursor: move;
}

.pointer-cursor {
  cursor: pointer;
}

// charity page styles
.charity-list, .charity-category-list {
  margin: 0 auto;
  border: solid 1px #ccc;
  min-height: 60px;
  font-size: 16px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.sort-icon {
  position: relative;
  top: 2px;
}

.white-text {
  color: white;
}

.red-background {
  background-color: darkred !important;
}

.inactive {
  color: #f44336;
}

.edit-icon {
  position: relative;
  top: 6px;

  &:hover {
    cursor: pointer;
  }
}

.custom-card-border {
  border-radius: 6px;
  padding: 8px;
  border: 1px solid rgb(232, 232, 232);
}

.copy-icon {
  font-size: 20px;
  position: relative;
  top: 7px;
  left: 5px;
  &:hover {
    cursor: pointer;
  }
}

.href-text {
  margin-left: 6px;
  text-decoration: underline;

  &:active  {
    color: purple !important;
    cursor: pointer;
  }

  &:hover {
    color: blue;
    cursor: pointer;
  }
}

.error-message {
  color: white;
}

.error-message-container {
  padding: 8px;
  margin: 16px 16px 0 16px;
  background-color: darkred;
  border-radius: 4px;
}

h4 {
  margin-top: 16px !important;
}

.rounded-card-button-small {
  border-radius: 6px;
  border: 1px solid green;
  width: calc(50% - 16px);
  text-align: center;

  mat-icon {
    height: unset;
    width: unset;
    font-size: 66px;
  }

  &:hover {
    cursor: pointer;
    background-color: #e7e7e7;
  }
}

.rounded-card-button {
  border-radius: 6px;
  padding: 32px;
  border: 1px solid green;
  text-align: center;

  mat-icon {
    height: unset;
    width: unset;
    font-size: 66px;
  }

  &:hover {
    cursor: pointer;
    background-color: #e7e7e7;
  }
}

// reporting styles
.report-label {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 16px;
  color: #686868;
}

.report-value {
  float: right;
  padding-bottom: 16px;
}

.bottom-border {
  border-bottom: 1px solid #e7e7e7;
}

.thick-bottom-border {
  border-bottom: 2px solid #e7e7e7;
}

.thick-top-border {
  border-top: 2px solid #e7e7e7;
}

.section-header {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
}

.ticket-type-header {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 8px;
}

hr {
  background-color: grey;
}

.flex-report-item {
  flex: 1 1 100%;
  box-sizing: border-box;
  max-width: 100%;
}

.resolved-icon {
  background-color: #28a745;
  color: white;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: bold;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
}

.escalated-icon {
  background-color: #D35400;
  color: white;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: bold;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
}

